import { Box, Text, Avatar } from "@twilio-paste/core";

import {
    getInnerContainerStyles,
    authorStyles,
    bodyStyles,
    outerContainerStyles,
    bubbleAndAvatarContainerStyles,
    getAvatarContainerStyles
} from "./styles/MessageBubble.styles";

export const ChatPing = () => {
    const promptAuthor = "Michael";
    const promptBody =
        "👋 Hi there! Can I help you get started with a new loan quote? Or do you have any questions I can answer first?";

    return (
        <Box {...outerContainerStyles} data-prompt-bubble>
            <Box {...bubbleAndAvatarContainerStyles}>
                <Box {...getAvatarContainerStyles(false)} data-testid="avatar-container">
                    <Avatar
                        size="sizeIcon40"
                        name="Michael"
                        src="https://periwinkle-wallaby-9607.twil.io/assets/michael1b.jpeg"
                    />
                </Box>
                <Box {...getInnerContainerStyles(false)}>
                    <Text {...authorStyles} as="p" title={promptAuthor}>
                        {promptAuthor}
                    </Text>
                    <Text as="p" {...bodyStyles}>
                        {promptBody}
                    </Text>
                </Box>
            </Box>
        </Box>
    );
};
