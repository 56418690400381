import { useExitIntent } from "use-exit-intent";
import { Box } from "@twilio-paste/core/box";
import { ChatIcon } from "@twilio-paste/icons/esm/ChatIcon";
import { ChevronDownIcon } from "@twilio-paste/icons/esm/ChevronDownIcon";
import { useDispatch, useSelector } from "react-redux";

import { changeExpandedStatus } from "../store/actions/genericActions";
import { AppState } from "../store/definitions";
import { containerStyles } from "./styles/EntryPoint.styles";

declare global {
    interface Window {
        trackEvent: (event: string, data?: any) => void; // eslint-disable-line @typescript-eslint/no-explicit-any
        formOpen: boolean;
        trackingAttr: any;
    }
}

if (!window.trackEvent) {
    window.trackEvent = (event: string, data?: any) => {
        return [event, data];
    };
}

export const EntryPoint = () => {
    const dispatch = useDispatch();
    const expanded = useSelector((state: AppState) => state.session.expanded);

    const timeToOpen = window.trackingAttr && window.trackingAttr.hasCalc ? 30 : 12;

    const { registerHandler } = useExitIntent({
        cookie: {
            daysToExpire: 30,
            key: "use-exit-intent"
        },
        desktop: {
            triggerOnIdle: true,
            useBeforeUnload: false,
            triggerOnMouseLeave: true,
            delayInSecondsToTrigger: timeToOpen
        },
        mobile: {
            triggerOnIdle: true,
            delayInSecondsToTrigger: timeToOpen
        }
    });

    const openChatAutomatically = () => {
        if (!expanded && sessionStorage.getItem("hasAutoOpened") !== "true" && !window.formOpen) {
            dispatch(changeExpandedStatus({ expanded: true }));
            if (window.trackEvent) {
                window.trackEvent("Chat – Auto-Opened");
            }
            sessionStorage.setItem("hasAutoOpened", "true");
        }
    };

    registerHandler({
        id: "chatOpened",
        handler: () => openChatAutomatically()
    });

    const handleEntryPointClick = () => {
        if (window.trackEvent) {
            if (expanded === false) {
                window.trackEvent("Chat – Opened");
            } else {
                window.trackEvent("Chat – Minimized");
            }
        }
        dispatch(changeExpandedStatus({ expanded: !expanded }));
    };

    return (
        <Box as="button" data-test="entry-point-button" onClick={handleEntryPointClick} {...containerStyles}>
            {expanded ? (
                <ChevronDownIcon decorative={false} title="Minimize chat" size="sizeIcon80" />
            ) : (
                <ChatIcon decorative={false} title="Open chat" size="sizeIcon60" />
            )}
        </Box>
    );
};
