import { Box } from "@twilio-paste/core/box";
import { Text } from "@twilio-paste/core/text";
import { Button } from "@twilio-paste/core/button";
import { CloseIcon } from "@twilio-paste/icons/esm/CloseIcon";
import { useDispatch } from "react-redux";

import { changeExpandedStatus } from "../store/actions/genericActions";
// import { AppState } from "../store/definitions";
import { containerStyles, titleStyles } from "./styles/Header.styles";

declare global {
    interface Window {
        trackEvent: (event: string, data?: any) => void; // eslint-disable-line @typescript-eslint/no-explicit-any
    }
}

export const Header = ({ customTitle }: { customTitle?: string }) => {
    const dispatch = useDispatch();
    // const expanded = useSelector((state: AppState) => state.session.expanded);

    const handleMinimizeClick = () => {
        dispatch(changeExpandedStatus({ expanded: false }));
        if (window.trackEvent) {
            window.trackEvent("Chat – Minimized");
        }
    };

    return (
        <Box as="header" {...containerStyles}>
            <Text as="h2" {...titleStyles}>
                {customTitle || "Live Chat"}
            </Text>
            <Button variant="link" onClick={handleMinimizeClick} size="icon_small">
                <CloseIcon decorative={false} title="Minimize chat" color="colorTextInverse" />
            </Button>
        </Box>
    );
};
